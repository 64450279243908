export const normalizeDrawingItemPointsXY = (item: any) => {
  // Step 1: Find the lowest x and y values in the points array
  let lowestX = Infinity
  let lowestY = Infinity

  for (let i = 0; i < item.points.length; i += 2) {
    if (item.points[i] < lowestX) {
      lowestX = item.points[i]
    }
    if (item.points[i + 1] < lowestY) {
      lowestY = item.points[i + 1]
    }
  }

  // Step 2: Update item.x and item.y with the lowest values
  item.x = lowestX
  item.y = lowestY

  // Step 3: Normalize the points by subtracting the lowest x and y
  for (let i = 0; i < item.points.length; i += 2) {
    item.points[i] -= lowestX     // Reduce x-coordinate by lowestX
    item.points[i + 1] -= lowestY // Reduce y-coordinate by lowestY
  }
}
