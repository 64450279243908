<script setup lang="ts">
import Vue3Html2pdf from 'vue3-html2pdf'
import { useDrawingStore } from '@/store/drawing'
import { Sign } from '@/models/sign'
import { ActivityTimelineItem } from '@/models/activity-timeline'
import VuePdfEmbed from 'vue-pdf-embed'

interface IProps {
  sign?: Sign,
  printLoading?: boolean,
  activityTimelineItems?: ActivityTimelineItem[],
  signGlossaryRowsBySide?: any,
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  printLoading: false,
  activityTimelineItems: () => ([]),
  signGlossaryRowsBySide: () => ({}),
})

interface CustomEvents {
  (e: 'update:printLoading', value: boolean): void
}

const emit = defineEmits<CustomEvents>()

const drawingStore = useDrawingStore()

const htmlPdf = ref()

const tabs = computed(() => {
  const result = []

  if (props.sign && props.sign?.type_info && (props.sign?.type_info?.sides || props.sign?.type_info?.sides === 0)) {
    for (let i = 0; i <= props.sign?.type_info?.sides; i++) {
      result.push(i)
    }
  }

  return result
})

const maxDesignPdfHeight = computed(() => {
  return tabs.value.length <= 2 ? '250px' : '140px'
})

const artworkLoading = ref(true)

// Method to handle PDF loading
const handleArtworkRendered = () => {
  artworkLoading.value = false
}

const sides = ['A', 'B', 'C', 'D']

const onExportPdf = async (): Promise<void> => {
  const el: HTMLElement | null = document.getElementById('mapSnapshot')

  if (!el) {
    console.error('Element with id "mapSnapshot" not found')
    return
  }

  if (!drawingStore.stage || !drawingStore.stage.getStage()) {
    console.error('Stage not found')
    return
  }

  el.innerHTML = ''

  try {
    // Retrieve the Konva stage and make deep copies of the current scale and position
    const stage = drawingStore.stage.getStage()
    const currentScale = JSON.parse(JSON.stringify(drawingStore.scale.x)) // deep copy current stage Scale
    const currentPositionX = JSON.parse(JSON.stringify(stage.x())) // deep copy current stage Position X
    const currentPositionY = JSON.parse(JSON.stringify(stage.y())) // deep copy current stage Position Y

    // Get the PDF wrapper element and its dimensions
    const pdfWrapper = document.getElementById('map-wrapper')
    let pdfWrapperWidth = 0
    let pdfWrapperHeight = 0
    if (pdfWrapper) {
      pdfWrapperWidth = pdfWrapper.offsetWidth
      pdfWrapperHeight = pdfWrapper.offsetHeight
    }

    // Assuming drawingStore.bgImg.width and drawingStore.bgImg.height are the image dimensions
    const imageWidth = drawingStore.bgImg.width
    const imageHeight = drawingStore.bgImg.height

    // Calculate the scaling factors for width and height
    const widthScale = pdfWrapperWidth / imageWidth
    const heightScale = pdfWrapperHeight / imageHeight

    // Use the smaller scaling factor to ensure that the entire image fits within the container(pdfWrapper)
    const scale = Math.min(widthScale, heightScale) * 1.4

    // Apply the calculated scale and reset the position to (0, 0) for correct Map display
    stage.scale({x: scale, y: scale})
    stage.position({ x: 0, y: 0 })

    const mapSnapshot: HTMLImageElement = new Image()
    mapSnapshot.crossOrigin = 'Anonymous'
    mapSnapshot.src = stage.toDataURL({
      x: drawingStore.selectedItem.x * scale - 100,
      y: drawingStore.selectedItem.y * scale - 75,
      width: 250,
      height: 200,
      quality: 1,
      pixelRatio: 15,
      imageSmoothingEnabled: false
    })

    // Return to base size with better quality(with increased pixelRatio)
    mapSnapshot.width = 250
    mapSnapshot.height = 200

    // Apply CSS to control the display size
    mapSnapshot.style.maxHeight = '150px'
    mapSnapshot.style.width = 'auto' // Maintain aspect ratio
    mapSnapshot.style.height = 'auto' // Maintain aspect ratio

    // Update PDF with Map Snapshot
    el.appendChild(mapSnapshot)

    // Wait for the next tick to restore the stage to its original scale and position
    await nextTick(() => {
      stage.scale({x: currentScale, y: currentScale})
      stage.position({x: currentPositionX, y: currentPositionY})
    })
  } catch (e) {
    console.log(e)
  }
}

const useGeneratePdf = async () => {
  emit('update:printLoading', true)
  try {
    // Add Map Snapshot
    await onExportPdf()

    // Generate PDF
    htmlPdf.value.generatePdf()
  } catch (e) {
    console.log(e)
  } finally {
    emit('update:printLoading', false)
  }
}

const getDisplayText = (item: any): string => {
  const { text_1, text_2 } = item
  return [text_1, text_2].filter(Boolean).join(' ')
}

defineExpose({ useGeneratePdf })
</script>

<template>
  <vue3-html2pdf
    ref="htmlPdf"
    :enable-download="true"
    :manual-pagination="true"
    :show-layout="false"
    pdf-content-width="100%"
    pdf-orientation="landscape"
  >
    <template  v-slot:pdf-content>
      <section
        class="pdf-item"
        :style="`--max-design-pdf-height: ${maxDesignPdfHeight}`"
      >
        <h2 class="d-flex align-center justify-space-between sign-pdf-header-title">
          {{
            `${drawingStore?.project?.name} - ${drawingStore?.project?.location_name} -
             ${drawingStore?.selectedLocation?.name}`
          }}
          <VChip class="sign-pdf-header-status-name-chip" color="primary" variant="outlined">
            {{ sign?.sign_status_name }}
          </VChip>
        </h2>
        <table class="pdf-table">
          <tr>
            <td>
              <h4>Sign Number</h4>
              <span>{{ sign?.short_name }}</span>
            </td>
            <td>
              <h4>Client</h4>
              <span>{{ drawingStore?.project?.customer_name }}</span>
            </td>
            <td>
              <h4>FA Approver(s)</h4>
              <template v-if="sign?.responsible_list?.length">
                <div
                  v-for="(responsible, responsibleKey) in sign?.responsible_list"
                  :key="responsibleKey"
                >
                  {{ responsible?.first_name }} {{ responsible?.last_name }}
                </div>
              </template>
              <template v-else>
                {{ sign?.responsible_name }}
              </template>
            </td>
          </tr>
          <tr>
            <td
              :colspan="drawingStore?.stage?.getStage() ? 2 : 3"
              class="glossary-content-table-cell"
            >
              <h4 class="glossary-content-table-cell-header">Content (glossary and pictogram)</h4>
              <VRow class="glossary-content-table-cell-body">
                <VCol
                  v-for="(side, sideKey) in tabs"
                  :key="sideKey"
                >
                  <div class="pb-3">
                    <h4 class="text-center mb-0">
                      {{ sides[sideKey] }}
                    </h4>
                  </div>
                  <!-- Iterate over each glossaryRow (each glossaryRow is a new row) -->
                  <VRow
                    v-for="(glossaryRow, glossaryRowKey) in signGlossaryRowsBySide[sideKey]"
                    :key="glossaryRowKey"
                    class="glossary-content-table-row"
                  >
                    <!-- Iterate over each block (each block is a new column) -->
                    <VCol
                      v-for="(block, blockKey) in glossaryRow.sign_blocks"
                      :key="blockKey"
                      class="d-flex align-center glossary-content-table-column pt-1 pb-1"
                      :class="{
                              'justify-start': block.alignment === 1,
                              'justify-center': block.alignment === 2,
                              'justify-end': block.alignment === 3,
                              'border-0': blockKey + 1 === glossaryRow.sign_blocks?.length,
                            }"
                    >
                      <div
                        class="d-inline-flex flex-wrap"
                      >
                        <!-- Iterate over each blockItem (render step by step inside the block column) -->
                        <div
                          v-for="(blockItem, blockItemKey) in block.items"
                          :key="blockItemKey"
                          class="position-relative mx-0 px-1 d-flex align-center"
                          :class="{
                              'text-left': block.alignment === 1,
                              'text-center': block.alignment === 2,
                              'text-right': block.alignment === 3
                            }"
                        >
                          <template v-if="blockItem.item_type === 1">
                            <span :class="{ 'glossary-item-custom-text': blockItem?.is_custom_text }">
                              {{ getDisplayText(blockItem) }}
                            </span>
                          </template>
                          <template v-else-if="blockItem.item_type === 2">
                            <div
                              style="width: 12px; height: 12px"
                              class="d-flex align-center justify-center mx-0"
                            >
                              <img
                                :src="blockItem?.pic"
                                alt="icon"
                                style="width: 100%; height: 100%; object-fit: contain"
                              />
                            </div>
                          </template>
                        </div>
                      </div>
                    </VCol>
                    <VCol
                      cols="12"
                      class="pt-0 pb-1 ma-0"
                      :class="{ 'sign-glossary-bottom-divider': !!glossaryRow.bottom_divider }"
                    />
                  </VRow>
                </VCol>
              </VRow>
            </td>
            <td
              v-if="drawingStore?.stage?.getStage()"
              class="map-snapshot-table-cell"
            >
              <h4>Map Snapshot</h4>
              <div id="mapSnapshot"></div>
            </td>
          </tr>
          <tr v-if="sign?.artwork_link">
            <td colspan="3">
              <h4>
                Artwork
                <span
                  v-if="sign?.artwork_modified_date"
                  class="ml-1"
                >
                  -
                  {{ sign.artwork_modified_date }}
                </span>
              </h4>
              <VRow>
                <VCol
                  v-for="(side, sideKey) in tabs"
                  :key="sideKey"
                >
                  <h4 class="text-center mb-0">
                    {{ sides[sideKey] }}
                  </h4>
                  <VuePdfEmbed
                    :source="sign?.artwork_link"
                    :page="sideKey + 1"
                    class="artwork-pdf-page"
                    @rendered="handleArtworkRendered"
                  />
                </VCol>
              </VRow>
            </td>
          </tr>
          <tr>
            <td>
              <h4>History</h4>
              <div
                v-for="(activityTimelineItem, activityTimelineItemKey) in props.activityTimelineItems.slice(-5)"
                :key="activityTimelineItemKey"
                class="history-record"
              >
                <span class="mr-1">{{ activityTimelineItem.activity_time }}</span>
                <span>{{ activityTimelineItem.title }}</span>
                <div class="history-record__content">{{ activityTimelineItem?.content }}</div>
              </div>
            </td>
            <td>
              <h4>Annotation or comments</h4>
              {{ sign?.notes }}
            </td>
            <td>
              <h4>Approval Signature(s)</h4>
              <template v-if="sign?.responsible_list?.length">
                <div
                  v-for="(responsible, responsibleKey) in sign?.responsible_list"
                  :key="responsibleKey"
                  class="mb-3"
                >
                  <div class="approval-signature-line">
                    <span>{{ responsible?.first_name }} {{ responsible?.last_name }}</span>
                    <span class="approval-signature-underline"></span>
                  </div>
                  <div class="approval-signature-line">
                    <span>Date:</span>
                    <span class="approval-signature-underline"></span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="approval-signature-line">
                  <span>{{ sign?.responsible_name }}</span>
                  <span class="approval-signature-underline"></span>
                </div>
                <div class="approval-signature-line">
                  <span>Date:</span>
                  <span class="approval-signature-underline"></span>
                </div>
              </template>
            </td>
          </tr>
        </table>
      </section>
    </template>
  </vue3-html2pdf>
</template>

<style lang="scss">
.pdf-table {
  margin-top: 8px;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  color: var(--v-theme-on-surface);
  td {
    border: 0;
    border-collapse: collapse;
    padding: 5px 9px;
    vertical-align: top;
    font-size: 10px;
    line-height: 11px;
  }
  .sign-pic {
    img {
      width: 12px;
      height: 12px;
    }
  }
  h4 {
    color: #000000 !important;
    font-size: 12px;
    line-height: 13px;
  }

  tr:first-child {
    background: rgba(var(--v-theme-primary), 0.03);
  }

  .map-snapshot-table-cell {
    width: 250px;
  }

  .glossary-content-table-cell {
    .glossary-content-table-cell-body {
      font-size: 10px;
      line-height: 11px;
    }
  }

  .glossary-content-table-row {
    font-size: 8px;
    line-height: 9px;
    .glossary-content-table-column {
      border-inline-end-width: thin;
      border-inline-end-style: solid;
      border-inline-end-color: rgba(var(--v-border-color), var(--v-border-opacity));
    }
  }
}

.artwork-pdf-page {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  canvas {
    max-width: 100% !important;
    max-height: 150px !important;
  }
}

.design-pdf-wrapper {
  max-height: var(--max-design-pdf-height);
  max-width: 100%;
  .design-pdf {
    max-height: var(--max-design-pdf-height);
    max-width: 100%;
  }
  .vue-pdf-embed canvas {
    max-height: var(--max-design-pdf-height) !important;
    max-width: 100% !important;
    width: unset !important;
    height: unset !important;
  }
}

.history-record {
  font-size: 9px;
  line-height: 10px;
  .history-record__content {
    font-size: 8px;
    line-height: 9px;
  }
}

.approval-signature-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
}

.approval-signature-underline {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin-left: 10px;
  height: 14px;
}

.sign-glossary-bottom-divider {
  border-bottom: 10px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.glossary-item-custom-text {
  font-weight: bold;
  color: orange;
}

.sign-pdf-header-status-name-chip {
  font-size: 13px !important;
}

.sign-pdf-header-title {
  font-size: 14px;
  color: #000000;
}
</style>
