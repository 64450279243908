<script setup lang="ts">
import { VDataTableVirtual } from 'vuetify/labs/VDataTable'
import { $getLineItemHistory } from '@/api/brandings'
import { $getSignHistory } from '@/api/signs'

interface IProps {
  modelValue?: boolean,
  itemId?: number | null,
  itemType?: 'branding' | 'wayfinding',
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: false,
  itemId: null,
  itemType: 'branding',
})

interface CustomEvents {
  (e: 'update:modelValue', value: boolean): void
  (e: 'onClose'): void
}

const emit = defineEmits<CustomEvents>()

const historyRecords = ref([])
const historyRecordsLoading = ref(false)

const tableHeaders = [
  { key: 'item_status_name', title: 'Status', sortable: false },
  { key: 'sign_status_name', title: 'Status', sortable: false },
  { key: 'previous_status', title: 'Previous Status', sortable: false },
  { key: 'next_status', title: 'Next Status', sortable: false },
  { key: 'change_reason', title: 'Type', sortable: false },
  { key: 'user_name', title: 'User Name', sortable: false },
  { key: 'change_date', title: 'Date/Time', sortable: false },
  { key: 'responsible_name', title: 'Next Action (Responsible)', sortable: false }
]

const computedTableHeaders = computed(() => {
  if (props.itemType === 'wayfinding') {
    return tableHeaders.filter(i => i.key !== 'item_status_name')
  }
  return tableHeaders.filter(i => i.key !== 'sign_status_name')
})

const getHistoryRecords = async () => {
  historyRecordsLoading.value = true
  const filters = {
    id: props.itemId,
  }
  try {
    let getItemHistoryRequest = null
    switch (props.itemType) {
      case 'branding':
        getItemHistoryRequest = $getLineItemHistory
        break
      case 'wayfinding':
        getItemHistoryRequest = $getSignHistory
        break
      default:
        break
    }
    if (!getItemHistoryRequest) return

    const res = await getItemHistoryRequest(filters)
    historyRecords.value = res.data
  } catch (e) {
    console.log(e)
  } finally {
    historyRecordsLoading.value = false
  }
}

const closeDialog = () => {
  emit('update:modelValue', !props.modelValue)
}

const onClose = async () => {
  emit('onClose')
}

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    getHistoryRecords()
  }
})
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="1200"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard>
      <template #title>
        Status History
      </template>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            class="py-0 mt-1"
          >
            <VDataTableVirtual
              :headers="computedTableHeaders"
              :items="historyRecords"
              :loading="historyRecordsLoading"
              no-data-text="History records not found"
              hover
              density="compact"
            />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>
