<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'

interface IProps {
  artworkLink?: any,
  artworkModifiedDate?: string | null | undefined,
  signSides?: number,
  sides?: string[],
}

const props = withDefaults(defineProps<IProps>(), {
  artworkLink: null,
  artworkModifiedDate: null,
  signSides: 1,
  sides: () => (['A', 'B', 'C', 'D']),
})

interface CustomEvents {
  (e: 'update:modelValue', value: boolean): void
  (e: 'onClose'): void
  (e: 'onArtworkPdfRendered'): void
}

const emit = defineEmits<CustomEvents>()

const page = ref(1)
const totalPages = ref(1)
const artworkPdf = ref()
const artworkLoading = ref(true)

const handleArtworkRendered = () => {
  artworkLoading.value = false
  totalPages.value = artworkPdf.value.pageCount
  emit('onArtworkPdfRendered')
}
</script>

<template>
  <div>
    <template v-if="!artworkLink">
      <div class="pa-2 text-center">
        No Artwork
      </div>
    </template>
    <template v-else>
      <VOverlay
        v-model="artworkLoading"
        class="align-center justify-center"
        contained
        persistent
      >
        <VProgressCircular
          color="primary"
          indeterminate
        />
      </VOverlay>
      <div v-if="!artworkLoading" class="d-flex justify-content-end">
        <a
          target="_blank"
          :href="artworkLink"
        >
          Download
        </a>
      </div>
      <VuePdfEmbed
        ref="artworkPdf"
        :page="page"
        :source="artworkLink"
        @rendered="handleArtworkRendered"
      />
      <div class="pdf-pages">
        <template v-for="side in signSides + 1">
          <a
            class="pdf-page"
            :class="{ 'active': side  === page, 'disabled': side > totalPages }"
            @click="side > totalPages ? {} : page = side"
          >
            {{ sides[side - 1] }}
          </a>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.pdf-pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .pdf-page {
    cursor: pointer;
    padding: 2px 8px;
    margin: 0 2px;
    border: 1px solid #DC0000;
    border-radius: 50%;
    &.active {
      background: #DC0000;
      color: #fff;
    }
    &.disabled {
      background: rgba(#DC0000, 0.2);
      color: #fff;
      cursor: not-allowed;
    }
  }
}
</style>
